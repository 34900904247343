import { Header } from "../../components/Header"
import { Link } from "react-router-dom"
import ajax from "../../assets/ajax.png"
import bloquage from "../../assets/Metiers/bloquage.png"
import camera from "../../assets/Metiers/camera.png"
import plus from "../../assets/plus.png"
import { Helmet } from 'react-helmet';

export const Securite = () => {
    return(
        <div className="w-full flex flex-col justify-center items-center lg:text-2xl text-lg">
            <Helmet>
                <title>Gestion de la sécurité | D.H. Quartz</title>
            </Helmet>
            <Header title="Gestion de la sécurité"/>
            <div className="w-[80%] flex lg:flex-row flex-col items-center justify-between lg:space-y-0 space-y-6">
                <div className="lg:w-[45%] w-full flex justify-center items-center">
                    <img src={ajax} alt="Ajax" className="lg:w-[400px] w-[250px]" />
                </div>
                <div className="lg:w-[50%] w-full">
                    <p>La sécurité de nos maisons et de nos biens en général est une préoccupation que nous avons tous. L'entreprise D.H. Quartz est partenaire de l'expert de référence Sécuritas, nous sommes donc capables de réaliser toutes vos demandes pour la sécurisation de vos biens immobiliers. De l'alarme anti-intrusion à la surveillance continue par réseau de caméra de surveillance, nous sommes tout à fait qualifiés pour vous conseiller et installer un système personnalisé et sur mesure. Nous vous accompagnerons ensuite en ce qui concerne l'entretien du matériel installé.</p>
                </div>
            </div>
            <div className="w-full bg-black text-white flex justify-center items-center lg:pt-28 pt-16 lg:pb-20 pb-12 lg:mb-16 mb-10 lg:mt-24 mt-16 relative" id="plus">
                <div className="w-[80%] flex lg:flex-row flex-col items-center justify-between">
                    <div className="flex flex-col justify-between items-start lg:w-[50%] w-full lg:space-y-10 space-y-6">
                        <h2 className="lg:text-5xl text-3xl font-semibold">Protégez ce qui compte le plus avec D.H. Quartz</h2>
                        <p>L'entreprise D.H. Quartz met à votre service des techniciens expérimentés pour concevoir avec vous un réseau de surveillance et de sécurité car il est très important pour nous, surtout pour vous, que vous vous sentiez en sécurité chez nous. La première étape consiste à la pose d'un système d'alarme. Extensible et évolutif il est souvent la première et la meilleure barrière face aux intrus. Grâce aux dernières évolutions en télécommunication nous pouvons vous proposer une gamme de solutions reliées par RTC, VOIP, GMS, 4G, 5G ou IP, il est même possible d'avoir une combinaison de ses systèmes de transmission. Il est aussi possible de relier votre réseau de surveillance à votre smartphone grâce à des applications pour être informé en temps réel d'une situation. En ce qui concerne les abonnements rien de plus simple : grâce aux solutions Machine to Machine il vous sera aisé de rester sécurisé.</p>
                    </div>
                    <div className="lg:w-[45%] w-full flex justify-center items-center lg:mt-0 mt-6">
                        <img src={bloquage} alt="Bloquage" className="w-[90%]" />
                    </div>
                </div>
                <a href="#plus" className="bg-[#F5F5F5] rounded-full lg:w-[150px] lg:h-[150px] w-[70px] h-[70px] absolute flex justify-center items-center lg:-top-[75px] -top-[35px]">
                    <img src={plus} alt="Plus" className="w-[40%]" />
                </a>
            </div>
            <div className="w-[80%] flex lg:flex-row flex-col-reverse items-center justify-between">
                <div className="lg:w-[45%] w-full lg:mt-0 mt-6 flex justify-center items-center">
                    <img src={camera} alt="Caméra" className="w-[90%]" />
                </div>
                <div className="flex flex-col justify-between items-start lg:w-[50%] w-full space-y-10">
                    <p>Vous désirez encore plus de sécurité ? Le réseau de caméra de surveillance est la solution ultime. Si vous choisissez un système à réseau IP haute définition et analogique en 720p, 1080p ou 4K, nos techniciens auront la possibilité de réutiliser le câble existant. Vous aurez donc à votre disposition des images claires et fluides mais surtout vous aurez la possibilité de remplacer une caméra fixe par une caméra mobile. Il existe un grand nombre de modèles de caméra ; aussi vous pourrez choisir ce que qui vous convient le mieux et selon votre budget. Ces caméras peuvent être motorisées, à varifocale manuelle ou fixées. Pour des images encore plus optimales vous pouvez aussi opter pour un dôme motorisé rapide (PTZ), disponible avec illumination infrarouge et haute sensibilité permettant une vision en couleurs mêmes en cas de faible niveau d'éclairage ; Ces installations dernier cris nécessitent une gestion moderne, aussi nous gérons l'installation et la mise en service de vos logiciels de surveillance et même l'installation de vis applications mobiles. Ainsi grâce à notre expertise tous vos systèmes de sécurité et de contrôle d'accès pourront interagir entre eux.</p>
                </div>
            </div>
            <div className="w-full bg-black text-white flex justify-center items-center lg:pt-28 pt-12 lg:pb-20 pb-10 mt-16">
                <div className="w-[60%] flex flex-col items-center justify-center text-center space-y-10">
                    <h3 className="lg:text-3xl text-2xl font-semibold">Une question ? Un projet ? </h3>
                    <div>
                        <Link to="/contact" href="/contact" className="text-white bg-[#B6872D] px-5 py-2 rounded-2xl">Contactez-nous</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}