import { Link, useLocation } from "react-router-dom"
import Logo from "../../assets/logo.svg"
import React from "react"
import styled from "@emotion/styled"
import { Nav } from "react-bootstrap"
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import bas from '../../assets/bas.png'
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';

const StyledLink = styled(Link)`
    border-bottom: solid white 2px !important;
    padding: 10px 0;

    &:hover,
    &.active {
        border-bottom: solid #B6872D 2px !important
    }
`

const StyledStop = styled(Link)`
    border-bottom: solid white 2px !important;
    padding: 10px 0;

    &:hover,
    &.active {
        border-bottom: solid #B6872D 2px !important
    }
`

const StyledLinkOther = styled(Link)`
    border-bottom: solid white 2px !important;
    padding: 10px 0;

    &:hover,
    &.active {
        border-bottom: solid #B6872D 2px !important
    }
`

const StyledLinkSpec = styled(Link)`
    border-bottom: solid white 2px !important;
    padding: 10px 0;
`

const StyledTest = styled.div`
    background: blue ;
    padding: 10px 0;
`

const theme = createTheme({
    palette: {
        primary:{
            main: '#B6872D',
            secondary: '#FFFFFF',
            contrastText: '#FFFFFF'
        }
    }
})

function Navbar() {
    const location = useLocation()

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);

    const open = Boolean(anchorEl);
    const open2 = Boolean(anchorEl2);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    const theme = createTheme({
        palette: {
          primary: {
            main: '#B6872D',
          },
          secondary: {
            main: '#ebc173',
          },
        },
      });

    console.log(location)
    return (
        <div className="w-full flex flex-row items-center justify-center my-6">
            <Nav className="w-full lg:flex hidden justify-center items-center">
                <div className="w-11/12 flex flex-row justify-between items-center text-xl">
                    <Nav.Link
                        as={location.pathname === "/" ? StyledLinkSpec : StyledLinkSpec}
                        to="/" 
                        href="/" className="w-[220px]"
                    >
                        <img src={Logo} alt="Logo" className="w-full" />
                    </Nav.Link>
                    <div className="flex flex-row space-x-12">
                        
                            <Nav.Link
                                as={location.pathname === "/" ? StyledLink : location.pathname === "/contact" ? StyledStop : StyledLinkOther }
                                to="/" 
                                href="/"
                            >
                                Accueil
                            </Nav.Link>
                            <Nav.Link   
                                as={location.pathname === "/" ? StyledLink : StyledLinkOther}
                                to="/presentation" 
                                href="/presentation"
                            >
                                Présentation
                            </Nav.Link>
                            <Nav.Link 
                                as={location.pathname === "/" ? StyledLinkSpec : StyledLinkSpec}
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                className="flex flex-row items-center"
                            >
                                Métiers <img src={bas} alt="" className="w-[15px] h-[15px] ml-2" />
                            </Nav.Link>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                'aria-labelledby': 'basic-button',
                                }}
                                color="primary"
                            >
                                <MenuItem 
                                    onClick={handleClose}
                                >
                                    <Link 
                                        to="/gestion-des-acces" 
                                        className="w-full" 
                                        as={location.pathname === "/" ? StyledLinkSpec : StyledLinkSpec}
                                    >
                                        Gestion des accès
                                    </Link>
                                </MenuItem>
                                <MenuItem 
                                    onClick={handleClose}
                                >
                                    <Link 
                                        to="/gestion-de-la-securité" 
                                        className="w-full"
                                        as={location.pathname === "/" ? StyledLink : StyledLinkOther}    
                                    >
                                        Gestion de la sécurité
                                    </Link>
                                </MenuItem>
                                <MenuItem 
                                    onClick={handleClose}
                                >
                                    <Link 
                                        to="/gestion-du-patrimoine" 
                                        className="w-full"
                                        as={location.pathname === "/" ? StyledLink : StyledLinkOther}
                                    >
                                        Gestion du patrimoine
                                    </Link>
                                </MenuItem>
                                <MenuItem 
                                    onClick={handleClose}
                                >
                                    <Link 
                                        to="/gestion-des-temps-alarmes-incendies-et-ppms" 
                                        className="w-full"
                                        as={location.pathname === "/" ? StyledLink : StyledLinkOther}
                                    >
                                        Gestion des temps, alarmes incendies et PPMS
                                    </Link>
                                </MenuItem>
                            </Menu>
                            <Nav.Link 
                                as={location.pathname === "/" ? StyledLink : StyledLinkOther}
                                to="/photos"
                                href="/photos"
                            >
                                Photos
                            </Nav.Link>
                            <Nav.Link 
                                as={location.pathname === "/" ? StyledLink : StyledLinkOther}
                                to="/actualites"
                                href="/actualites"
                            >
                                Actualités
                            </Nav.Link>
                    </div>
                    <div className="w-[220px] flex justify-end">
                        <Nav.Link 
                            as={location.pathname === "/" ? StyledLinkSpec : StyledLinkSpec}
                            to="/contact" 
                            href="/contact"
                            className="bg-[#B6872D] text-white px-5 py-2 rounded-3xl w-5/6 text-center"
                        >
                            Contact
                        </Nav.Link>
                    </div>
                </div>
            </Nav>
            <div className="w-11/12 lg:hidden flex flex-row justify-between items-center">
                <Link to="/" className="w-[200px]">
                    <img src={Logo} alt="Logo" className="w-[100px]" />
                </Link>
                <div>
                    <ThemeProvider theme={theme}>
                        <Button
                            id="basic-button"
                            aria-controls={open2 ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open2 ? 'true' : undefined}
                            onClick={handleClick2}
                        >
                            <MenuIcon color="primary" />
                        </Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl2}
                            open={open2}
                            onClose={handleClose2}
                            MenuListProps={{
                            'aria-labelledby': 'basic-button',
                            }}
                            color="primary"
                        >
                            <MenuItem onClick={handleClose}><Link to="/" className="w-full">Accueil</Link></MenuItem>
                            <MenuItem onClick={handleClose}><Link to="/presentation" className="w-full">Présentation</Link></MenuItem>
                            <MenuItem
                                as={location.pathname === "/" ? StyledLinkSpec : StyledLinkSpec}
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                className="flex flex-row items-center text-center justify-center"
                            >
                                Métiers <img src={bas} alt="" className="w-[15px] h-[15px] ml-2" />
                            </MenuItem>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                'aria-labelledby': 'basic-button',
                                }}
                                color="primary"
                            >
                                <MenuItem 
                                    onClick={handleClose}
                                >
                                    <Link 
                                        to="/gestion-des-acces" 
                                        className="w-full" 
                                        as={location.pathname === "/" ? StyledLinkSpec : StyledLinkSpec}
                                    >
                                        Gestion des accès
                                    </Link>
                                </MenuItem>
                                <MenuItem 
                                    onClick={handleClose}
                                >
                                    <Link 
                                        to="/gestion-de-la-securité" 
                                        className="w-full"
                                        as={location.pathname === "/" ? StyledLink : StyledLinkOther}    
                                    >
                                        Gestion de la sécurité
                                    </Link>
                                </MenuItem>
                                <MenuItem 
                                    onClick={handleClose}
                                >
                                    <Link 
                                        to="/gestion-du-patrimoine" 
                                        className="w-full"
                                        as={location.pathname === "/" ? StyledLink : StyledLinkOther}
                                    >
                                        Gestion du patrimoine
                                    </Link>
                                </MenuItem>
                                <MenuItem 
                                    onClick={handleClose}
                                >
                                    <Link 
                                        to="/gestion-des-temps-alarmes-incendies-et-ppms" 
                                        className="w-full truncate ..."
                                        as={location.pathname === "/" ? StyledLink : StyledLinkOther}
                                    >
                                        Gestion des temps, alarmes incendies et PPMS
                                    </Link>
                                </MenuItem>
                            </Menu>
                            <MenuItem onClick={handleClose}><Link to="/photos" className="w-full">Photos</Link></MenuItem>
                            <MenuItem onClick={handleClose}><Link to="/actualites" className="w-full">Actualités</Link></MenuItem>
                            <MenuItem onClick={handleClose}>
                                <Link to="/contact" className="bg-[#B6872D] text-white px-5 py-2 rounded-3xl w-full text-center">
                                    <p>Contact</p>
                                </Link>
                            </MenuItem>
                        </Menu>
                    </ThemeProvider>
                </div>
            </div>
        </div>
    )
}

export default Navbar