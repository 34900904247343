import { Header } from "../../components/Header"
import { Link } from "react-router-dom"
import digicode from "../../assets/digicode.png"
import portail from "../../assets/Metiers/portail.jpg"
import badge from "../../assets/Metiers/badge.jpg"
import plus from "../../assets/plus.png"
import { Helmet } from 'react-helmet';

export const Acces = () => {
    return(
        <div className="w-full flex flex-col justify-center items-center lg:text-2xl text:lg">
            <Helmet>
                <title>Gestion des accès | D.H. Quartz</title>
            </Helmet>
            <Header title="Gestion des accès"/>
            <div className="w-[80%] flex lg:flex-row flex-col items-center justify-between lg:space-y-0 space-y-6">
                <div className="lg:w-[45%] w-full flex justify-center items-center">
                    <img src={digicode} alt="Digicode" className="lg:w-[250px] w-[150px]" />
                </div>
                <div className="lg:w-[50%] w-full">
                    <p>Un contrôle d'accès est idéal pour assurer une véritable sécurité de votre bâtiment. Vous souhaitez sécuriser votre domicile, entreprise ou commerce avec un portail automatique ? Pour votre tranquillité d'esprit vous voulez installer un portier électronique à badge voire biométrique à l'entrée de votre bâtiment ? Nos artisans sauront vous donner des conseils sur mesure et réaliser l'ensemble des travaux nécessaires. Après l'installation du matériel, nous assurons la maintenance de vos barrières, portails et serrures électriques.</p>
                </div>
            </div>
            <div className="w-full bg-black text-white flex justify-center items-center pt-28 pb-20 my-16 relative" id="plus">
                <div className="w-[80%] flex lg:flex-row flex-col items-center justify-between lg:space-y-0 space-y-6">
                    <div className="flex flex-col justify-between items-start lg:w-[50%] w-full lg:space-y-10 space-y-4">
                        <h2 className="lg:text-5xl text-3xl font-semibold">L'Assurance d'un accès sécurisé :</h2>
                        <p>Depuis 30 ans nous nous occupons de l'automatisation et la sécurisation de vos ouvertures afin de vous garantir un accès sécurisé et contrôlé.</p>
                        <p>Pour la pose et l'entretien des portails coulissants, à battants et barrières automatiques à commandes radio ou GSM. Pour l'installation de dispositifs de sécurité comme les photocellules, barres palpeuses ou encore feux de signalisation, nous sommes des experts.</p>
                    </div>
                    <div className="lg:w-[45%] w-full flex justify-center items-center">
                        <img src={portail} alt="Portail" className="w-[90%]" />
                    </div>
                </div>
                <a href="#plus" className="bg-[#F5F5F5] rounded-full lg:w-[150px] lg:h-[150px] w-[70px] h-[70px] absolute flex justify-center items-center lg:-top-[75px] -top-[35px]">
                    <img src={plus} alt="Plus" className="w-[40%]" />
                </a>
            </div>
            <div className="w-[80%] flex lg:flex-row flex-col-reverse items-center justify-between">
                <div className="lg:w-[45%] w-full flex justify-center items-center pt-6 lg:h-[500px] h-[300px] object-cover">
                    <img src={badge} alt="Badge" className="w-[90%] h-full object-cover" />
                </div>
                <div className="flex flex-col justify-between items-start lg:w-[50%] w-full">
                    <p>Pour garantir un fonctionnement optimal de votre entreprise ou collectivité, nous nous chargeons aussi des accès de vos locaux. Nous mettons en place et assurons la maintenance ou la réparation de vos systèmes comme les claviers autonomes, lecteurs de badges, de proximité ou biométriques, des cylindres électroniques, des gâches, serrures électriques, ventouses et bandeaux électromagnétiques.</p>
                </div>
            </div>
            <div className="w-full bg-black text-white flex justify-center items-center lg:pt-28 pt-12 lg:pb-20 pb-10 mt-16">
                <div className="w-[60%] flex flex-col items-center justify-center text-center space-y-10">
                    <h3 className="lg:text-3xl text-2xl font-semibold">Une question ? Un projet ? </h3>
                    <div>
                        <Link to="/contact" href="/contact" className="text-white bg-[#B6872D] px-5 py-2 rounded-2xl">Contactez-nous</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}