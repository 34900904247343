import { Header } from "../../components/Header"
import { Link } from "react-router-dom"
import plus from "../../assets/plus.png"
import horloge from "../../assets/horloge.png"
import alarme from "../../assets/Metiers/alarme.png"
import secu from "../../assets/Metiers/secu.png"
import { Helmet } from 'react-helmet';

export const Temps = () => {
    return(
        <div id="top" className="w-full flex flex-col justify-center items-center lg:text-2xl text-lg">
            <Helmet>
                <title>Gestion des temps, alarmes incendies et PPMS | D.H. Quartz</title>
            </Helmet>
            <Header title="Gestion des temps, alarmes incendies et PPMS"/>
            <div className="w-[80%] flex lg:flex-row flex-col items-center justify-between">
                <div className="lg:w-[45%] w-full flex justify-center items-center">
                    <img src={horloge} alt="Horloge" className="lg:w-[400px] w-[250px]" />
                </div>
                <div className="lg:w-[50%] w-full lg:mt-0 mt-6">
                    <p>Quand on a une entreprise employant un certain nombre d'employés, cela peut être difficile de suivre la ponctualité de chacun. Grâce à notre large de gamme de système de contrôle du temps nous pouvons offrir des solutions qui vous seront adaptées. Pour être tous à la même heure, nous nous occupons de l'installation d'affichage d'heure simple ou en réseau, filaire ou sans fil DHF pour votre entreprise, terrain de sport ou affichage public. Nous sommes aussi spécialistes dans la pose d'alarme incendie notamment dans les établissements recevant du public ( écoles, collèges, lycées, etc.)</p>
                </div>
            </div>
            <div className="w-full bg-black text-white flex justify-center items-center lg:pt-28 pt-16 lg:pb-20 pb-12 lg:mb-16 mb-10 lg:mt-24 mt-16 relative" id="plus">
            <div className="w-[80%] flex lg:flex-row flex-col items-center justify-between">
                    <div className="flex flex-col justify-between items-start lg:w-[50%] w-full lg:space-y-10 space-y-6">
                        <h2 className="lg:text-5xl text-3xl font-semibold">Sécurité et efficacité en entreprise avec D.H. QUARTZ</h2>
                        <p>Pour le bon fonctionnement de votre entreprise, D.H. Quartz est à vos côtés pour installer de la distribution d'heure synchronisée et des contrôles de présence. En ce qui concerne la distribution d'heure optez soit pour un système filaire soit pour une distribution d'heure sans fil DHE. En ce qui concerne le contrôle de présence; grâce à un pointage à carte ou à badges, vous gagnerez en efficacité et en rendement.</p>
                        <p>Notre entreprise est aussi présente dans un grand nombre d'ERP sur le territoire des Ardennes ( Établissements Recevant du Public), donc si vous avez un restaurant, un hôtel, une micro crèche, une bibliothèque, un collège, la liste n'est pas exhaustive ; vous êtes soumis à des règles de sécurité très strictes car vous recevez des personnes extérieures et c'est à vous de vous assurez que l’accueil de ces personnes soit sécuritaire.</p>
                        <a href="https://entreprendre.service-public.fr/vosdroits/F31684" target="_blank" rel="noreferrer">(https://entreprendre.service-public.fr/vosdroits/F31684). </a>
                    </div>
                    <div className="lg:w-[45%] w-full flex justify-center items-center lg:mt-0 mt-6">
                        <img src={secu} alt="Sécu" className="w-[90%]" />
                    </div>
                </div>
                <a href="#plus" className="bg-[#F5F5F5] rounded-full lg:w-[150px] lg:h-[150px] w-[70px] h-[70px] absolute flex justify-center items-center lg:-top-[75px] -top-[35px]">
                    <img src={plus} alt="Plus" className="w-[40%]" />
                </a>
            </div>
            <div className="w-[80%] flex lg:flex-row flex-col-reverse items-center justify-between">
                <div className="lg:w-[45%] w-full lg:mt-0 mt-6 flex justify-center items-center">
                    <img src={alarme} alt="Alarme" className="w-[90%]" />
                </div>
                <div className="flex flex-col justify-between items-start lg:w-[50%] w-full space-y-10">
                    <p>Nos techniciens sauront vous faire un diagnostique clair et précis de ce dont vous avez besoin pour que votre établissement soit en règle. Nous sommes des experts dans l'installation de votre système incendie. Depuis plus de 30 ans nous sommes des distributeurs privilégiés d'AVISS, nous assurons l'installation et la maintenance de vos réseaux d'alarme incendie afin d'assurer une sécurité maximale pour les personnes présentes dans vos bâtiments. Du détecteur de fumée aux déclencheurs manuels d'alarme en passant par les diffuseurs sonores et lumineux, la gamme AVISS garantit une tranquillité d'esprit pour tous ses utilisateurs afin de les aider dans ces moments que l'on souhaite ne jamais avoir à vivre.</p>
                </div>
            </div>
            <div className="w-full bg-black text-white flex justify-center items-center lg:pt-28 pt-12 lg:pb-20 pb-10 mt-16">
                <div className="w-[60%] flex flex-col items-center justify-center text-center space-y-10">
                    <h3 className="lg:text-3xl text-2xl font-semibold">Une question ? Un projet ? </h3>
                    <div>
                        <Link to="/contact" href="/contact" className="text-white bg-[#B6872D] px-5 py-2 rounded-2xl">Contactez-nous</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}